define("discourse/plugins/discourse-fcm-notifications/discourse/templates/connectors/user-preferences-notifications/user-fcm-notification-input", ["exports", "ember-this-fallback/is-component", "ember-this-fallback/this-fallback-helper", "ember-this-fallback/try-lookup-helper", "@ember/template-factory"], function (_exports, _isComponent, _thisFallbackHelper, _tryLookupHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{fcm-notification-config}}
  
  */
  {
    "id": "Ew8O48Bu",
    "block": "[[[41,[28,[32,0],[\"fcm-notification-config\"],null],[[[8,[39,1],null,null,null]],[]],[[[44,[[28,[37,3],null,[[\"fcm-notification-config\"],[[28,[32,1],[\"fcm-notification-config\"],null]]]]],[[[1,[52,[30,1,[\"fcm-notification-config\"]],[28,[30,1,[\"fcm-notification-config\"]],null,null],[28,[32,2],[[30,0],\"fcm-notification-config\",\"[\\\"The `fcm-notification-config` property path was used in the `discourse/plugins/discourse-fcm-notifications/discourse/templates/connectors/user-preferences-notifications/user-fcm-notification-input.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.fcm-notification-config}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]\"],null]]]],[1]]]],[]]],[1,\"\\n\"]],[\"maybeHelpers\"],false,[\"if\",\"fcm-notification-config\",\"let\",\"hash\"]]",
    "moduleName": "discourse/plugins/discourse-fcm-notifications/discourse/templates/connectors/user-preferences-notifications/user-fcm-notification-input.hbs",
    "scope": () => [_isComponent.default, _tryLookupHelper.default, _thisFallbackHelper.default],
    "isStrictMode": false
  });
});