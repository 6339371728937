define("discourse/plugins/discourse-fcm-notifications/discourse/components/fcm-notification-config", ["exports", "discourse-common/utils/decorators", "@ember/component", "@ember/object/computed", "discourse/plugins/discourse-fcm-notifications/discourse/lib/fcm-notifications"], function (_exports, _decorators, _component, _computed, _fcmNotifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    showFcmNotification() {
      return this.siteSettings.fcm_notifications_enabled;
    },
    has_subscription: (0, _computed.empty)("subscription"),
    disabled: (0, _computed.or)("has_subscription", "loading"),
    loading: false,
    errorMessage: null,
    calculateSubscribed() {
      this.set("fcmNotificationSubscribed", this.currentUser.custom_fields.discourse_fcm_notifications != null);
    },
    fcmNotificationSubscribed: null,
    init() {
      this._super(...arguments);
      this.setProperties({
        fcmNotificationSubscribed: this.currentUser.custom_fields.discourse_fcm_notifications != null,
        errorMessage: null
      });
    },
    actions: {
      subscribe() {
        this.setProperties({
          loading: true,
          errorMessage: null
        });
        (0, _fcmNotifications.subscribe)(this.subscription).then(response => {
          if (response.success) {
            this.currentUser.custom_fields.discourse_fcm_notifications = this.subscription;
            this.calculateSubscribed();
          } else {
            this.set("errorMessage", response.error);
          }
        }).finally(() => this.set("loading", false));
      },
      unsubscribe() {
        this.setProperties({
          loading: true,
          errorMessage: null
        });
        (0, _fcmNotifications.unsubscribe)().then(response => {
          if (response.success) {
            this.currentUser.custom_fields.discourse_fcm_notifications = null;
            this.calculateSubscribed();
          } else {
            this.set("errorMessage", response.error);
          }
        }).finally(() => this.set("loading", false));
      }
    }
  }, [["method", "showFcmNotification", [_decorators.default]]]));
});